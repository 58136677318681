<template>
  <div class="anchor">
    <div class="anchor_info">
      <img
        :src="filesCdnDomain + props.livingInfo.avatar"
        alt=""
        class="head"
        v-if="props.livingInfo.avatar"
      />
      <img src="@/assets/img/icon/avart.png" alt="" v-else class="head" />
      <div class="info">
        <div class="name">
          {{ props.livingInfo.nickName }}
          <img class="level" :src="anchorLevel[props.livingInfo.anchorLevel]" />
        </div>
        <div class="info_msg">
          {{ props.signature }}
        </div>
      </div>
      <div class="follow" v-if="props.livingInfo.isAttented == 0" @click="followBtn">
        <img src="@/assets/img/icon/follow.png" alt="" class="follow_icon" />
        关注
      </div>
      <div class="right_btn" v-else @click="cancleFollow">已关注</div>
    </div>
    <div class="title">
      <img src="@/assets/img/icon/notice.png" alt="" class="notice_icon" />公告
    </div>
    <div class="notice">
      <div class="notice_list">
        <div class="notice_item">
          {{ props.livingInfo.notice }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup name="containerBox">
import { cancelFollow, addFollow } from "@/assets/api/user";
import { useStore } from "vuex";
import { anchorLevel } from "@/untils/level-icon";
import { ref, computed } from "vue";
import { showSuccessToast, showConfirmDialog } from "vant";
const store = useStore();
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const isLogin = ref(localStorage.getItem("isLogin"));
const props = defineProps({
  livingInfo: {
    type: Object,
    default: {},
  },
  signature: {
    type: String,
    default: "",
  },
});
function followBtn() {
  if (!isLogin.value) {
    showConfirmDialog({
      title: "提示",
      message: "未登录，是否先登录",
    })
      .then(() => {
        router.push({
          name: "Login",
        });
      })
      .catch(() => {});
    return;
  }
  addFollow({ uid: props.livingInfo.anchorid }).then((res) => {
    props.livingInfo.isAttented = 1;
  });
}
function cancleFollow() {
  cancelFollow({
    uid: props.livingInfo.anchorid,
  }).then((res) => {
    showSuccessToast("已取消关注");
    props.livingInfo.isAttented = 0;
  });
}
</script>
<style scoped>
.anchor {
  overflow-y: scroll;
  height: 100%;
}
.anchor::-webkit-scrollbar {
  display: none;
}
.anchor_info {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 6px 3.73vw 0px rgba(23, 25, 31, 0.04);
  margin: 3.46vw 3.73vw 0;
  height: 18.13vw;
  padding: 0 3.2vw 0 2.66vw;
  display: flex;
  align-items: center;
}
.head {
  width: 11.73vw;
  height: 11.73vw;
  border-radius: 50%;
  margin-right: 8px;
}
.info {
  flex: 1;
}
.name {
  color: #222;
  font-family: PingFang SC;
  font-size: 3.46vw;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.level {
  width: 10.66vw;
  margin-left: 4px;
}

.info_msg {
  width: 59.73vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-weight: 400;
}

.right_btn {
  padding: 4px 6px;
  border-radius: 4px;
  border: 0.5px solid #d1d5db;
  box-shadow: 2px 2px 4px 0px rgba(216, 173, 109, 0.2);
  color: rgba(0, 0, 0, 0.8);
  font-family: PingFang SC;
  font-size: 2.93vw;
  font-weight: 600;
  line-height: 3.73vw;
}
.follow_icon {
  width: 3.2vw;
  height: 3.2vw;
}
.follow {
  color: #fff;
  font-family: PingFang SC;
  font-size: 2.93vw;
  font-weight: 600;
  line-height: 3.73vw;
  width: 12.8vw;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: linear-gradient(270deg, #d8ad6d 0%, #f5d6a8 100%);
  box-shadow: 2px 2px 4px 0px rgba(216, 173, 109, 0.2);
}
.notice {
  padding: 0 3.73vw 25vw;
}
.title {
  display: flex;
  align-items: center;
  color: #000;
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-weight: 600;
  margin: 5.33vw 0 2.66vw 3.73vw;
}
.notice_icon {
  width: 3.73vw;
  height: 3.73vw;
  margin-right: 4px;
}
.notice_item {
  border-radius: 8px;
  background: #fff;
  color: #4b5563;
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-weight: 400;
  padding: 4.26vw;
  text-align: left;
  margin-bottom: 2.66vw;
}
</style>
