<template>
  <div class="msg_details_live">
    <div class="head">
      <img src="../../../../assets/img/icon/back.png" alt="" @click="goBack" class="back_icon" />

      <div class="msg_name" v-if="imChatType == ''"></div>
      <!-- 单聊时，若有备注名称显示备注名称 -->
      <div class="msg_name" v-if="imChatType == 'GROUP' && conversationProfileInfo.groupProfile">
        {{ conversationProfileInfo.groupProfile.name }}
      </div>
      <div class="msg_name" v-else-if="imChatType == 'C2C'">
        {{
          conversationProfileInfo.remark
            ? conversationProfileInfo.remark
            : conversationProfileInfo.userProfile
            ? conversationProfileInfo.userProfile.nick
            : " "
        }}
      </div>
      <div class="right" v-if="imChatType == 'C2C'">
        <!-- 关注按钮  单聊显示 -->
        <div class="follow_btn" v-if="basicUserInfo.isAttented == 0">
          <img src="@/assets/img/icon/follow.png" alt="" @click.stop="followBtn" />关注
        </div>
        <div class="followed_btn" @click.stop="cancleFollow" v-if="basicUserInfo.isAttented == 1">
          已关注
        </div>
      </div>
    </div>

    <div class="msg_contaier_box">
      <MessageContainer
        @getAllProhibitioValue="_getAllProhibitioValue"
        @getUserProhibitioValue="_getUserProhibitioValue"
      />
    </div>

    <div class="send_box">
      <MessageSendBox
        :allProhibitioValue="allProhibitioValue"
        :userProhibitioValue="userProhibitioValue"
      />
    </div>
  </div>
</template>
<script setup name="MsgDetails">
import { chat } from "@/untils/tim";
import TencentCloudChat from "@tencentcloud/chat";
import { useRouter } from "vue-router";
import MessageSendBox from "../../../message/components/messageSendBox.vue";
import MessageContainer from "../../../message/components/messageContainer.vue";
import { nextTick, ref, watchEffect, computed } from "vue";
import { basicInfo, cancelFollow, addFollow } from "@/assets/api/user";
import { useStore } from "vuex";
const emit = defineEmits(["goBack"]);
const conversationProfileInfo = ref({}); //当前会话资料
const basicUserInfo = ref({}); //当前用户资料
const allProhibitioValue = ref(false); //保存全体是否被禁言
const userProhibitioValue = ref(false); // //保存我是否被禁言
const imChatType = ref("");
const imChatId = ref("");
const store = useStore();
const userId = ref(0);
const userInfo = computed(() => store.state.userJs.userInfo);
const props = defineProps({
  msgBoxShow: {
    type: Boolean,
    default: true,
  },
});
watchEffect(() => {
  if (!props.msgBoxShow) {
    _getConversationProfile();
  }
});
function goBack() {
  emit("goBack");
}
function _getAllProhibitioValue(v) {
  nextTick(() => {
    allProhibitioValue.value = v;
  });
}
function _getUserProhibitioValue(v) {
  nextTick(() => {
    userProhibitioValue.value = v;
  });
}
function getProhibitio() {
  chat()
    .getGroupMemberProfile({
      //查询自己是否被禁言
      groupID: localStorage.getItem("imChatId"),
      userIDList: [userInfo.value.uid + ""], // 请注意：即使只拉取一个群成员的资料，也需要用数组类型，例如：userIDList: ['user1']
    })
    .then((res) => {
      console.log(res)
      let timeNew = new Date().getTime();
      if (res.data.memberList.length == 0) return;
      if (
        !res.data.memberList[0].mutedUntil ||
        res.data.memberList[0].mutedUntil * 1000 < timeNew
      ) {
        userProhibitioValue.value = false;
      } else {
        userProhibitioValue.value = true;
      }
    });
}
//获取会话信息
function _getConversationProfile() {
  imChatType.value = localStorage.getItem("imChatType");
  imChatId.value = localStorage.getItem("imChatId");
  chat()
    .getConversationProfile(imChatType.value + imChatId.value)
    .then((imResponse) => {
 
      conversationProfileInfo.value = imResponse.data.conversation;
      if (imChatType.value == "GROUP") {
             getProhibitio();
        allProhibitioValue.value = imResponse.data.conversation.groupProfile.muteAllMembers;
      } else {
        userId.value = imResponse.data.conversation.userProfile.userID * 1;
        _basicInfo(imResponse.data.conversation.userProfile.userID * 1);
      }
    });
}
function _basicInfo(id) {
  basicInfo({ platform: 3, userId: id }).then((res) => {
    if (!res.data) return;
    basicUserInfo.value = res.data;
  });
}
function followBtn() {
  addFollow({ uid: userId.value }).then((res) => {
    showSuccessToast("关注成功");
    basicUserInfo.value.isAttented = 1;
  });
}
function cancleFollow() {
  cancelFollow({
    uid: userId.value,
  }).then((res) => {
    showSuccessToast("已取消关注");
    basicUserInfo.value.isAttented = 0;
  });
}
</script>

<style scoped>
.msg_details_live {
  background: #f3f5f6;
}
.msg_contaier_box {
  position: fixed;
  top: 93.32vw;
  bottom: 16vw;
  left: 0;
  right: 0;
}
.head {
  position: fixed;
  left: 0;
  right: 0;
  height: 10.66vw;
  display: flex;
  align-items: center;
  padding: 0 3.73vw;
  background-color: #fff;
}
.back_icon {
  width: 5.33vw;
  height: 5.33vw;
}
.msg_name {
  flex: 1;
  text-align: left;
  color: #000;
  font-family: PingFang SC;
  font-size: 4.26vw;
  font-weight: 500;
  margin-left: 3.73vw;
}
.right {
  display: flex;
  align-items: center;
}
.more {
  width: 6.4vw;
  height: 6.4vw;
}
.followed_btn {
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-style: normal;
  font-weight: 600;
  line-height: 3.73vw;
  border-radius: 6px;
  padding: 6px 10px;
  border: 0.5px solid #d1d5db;
  background: #fff;
}
.follow_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 6px;
  background: #d8ad6d;
  text-align: center;
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-weight: 600;
  width: 14.93vw;
  height: 6.93vw;
}
.follow_btn img {
  width: 3.2vw;
  height: 3.2vw;
}

.send_box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
