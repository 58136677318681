<template>
  <div class="msgGift">
    <img
      src="@/assets/img/icon/administrators.png"
      v-if="props.message.role == 2"
      alt=""
      class="administrators"
    />
    <img
      src="@/assets/img/icon/an_icon.png"
      alt=""
      class="administrators"
      v-if="props.message.role == 1"
    />
    <img :src="noble(props.message.vipLevel)" alt="" v-if="props.message.isVip" class="noble" />
    <img class="level" :src="lLevel[props.message.userLevel * 1]" />
    <span class="name">{{ props.message.nickName }}</span>
    <span class="d_text">
      送出{{ props.message.gift.giftCount }}个{{ props.message.gift.giftTitle }}</span
    >

    <img :src="filesCdnDomain + props.message.gift.giftIcon" alt="" class="gift" />
  </div>
</template>
<script setup name="msgGift">
import { useStore } from "vuex";
import { lLevel } from "@/untils/level-icon";
import { ref, computed } from "vue";
const store = useStore();
const props = defineProps({
  message: {
    type: Object,
    default: {},
  },
});
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
</script>
<style scoped>
.msgGift {
  text-align: left;
  margin-bottom: 1.6vw;
}
.administrators {
  width: 10.13vw;
  margin-right: 2px;
  vertical-align: middle;
}
.noble {
  width: 6.4vw;
  height: auto;
  margin-right: 2px;
  vertical-align: middle;
}
.level {
  width: 10.66vw;
  height: auto;
  vertical-align: middle;
}
.name {
  color: #6898df;
  font-family: PingFang SC;
  font-size: 3.46vw;
  font-weight: 400;
  line-height: 5.86vw;
}
.d_text {
  color: #94a3b8;
  font-family: PingFang SC;
  font-size: 3.46vw;
  font-weight: 400;
  line-height: 5.86vw;
  overflow-wrap: break-word;
}
.gift {
  width: 24px;
  vertical-align: middle;
  margin-left: 2px;
}
</style>
