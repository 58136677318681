<template>
  <div class="msgDefault">
    <img
      src="@/assets/img/icon/administrators.png"
      alt=""
      v-if="props.message.role == 2"
      class="administrators"
    />
    <img
      src="@/assets/img/icon/an_icon.png"
      alt=""
      class="administrators"
      v-if="props.message.role == 1"
    />
    <img :src="noble(props.message.vipLevel)" alt="" v-if="props.message.isVip" class="noble" />
    <img class="level" :src="lLevel[props.message.userLevel * 1]" />
    <span class="name">{{ props.message.nickName }}</span>
    <span class="d_text d_text_c" v-if="props.message.isVip">骑着{{ getNoble() }}驾临直播间</span>
    <span class="d_text" v-else>{{ props.message.message }} </span>
  </div>
</template>
<script setup name="msgCar">
import { useStore } from "vuex";
import { lLevel } from "@/untils/level-icon";
import { noble } from "@/untils/index";
import { ref, computed } from "vue";
const store = useStore();
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const props = defineProps({
  message: {
    type: Object,
    default: {},
  },
});
function getNoble() {
  let name = "";
  // 铁骑 -- 战驹 领主--玄狼 勋爵--战虎 亲王--雄狮 帝皇----赤龙
  switch (props.message.vipLevel) {
    case 1:
      name = "铁峻战驹";
      break;
    case 2:
      name = "冰霜玄狼";
      break;
    case 3:
      name = "神威战虎";
      break;
    case 4:
      name = "耀金雄狮";
      break;
    case 5:
      name = "焚天赤龙";
      break;
    default:
      name = "";
  }
  return name;
}
</script>
<style scoped>
.msgDefault {
  text-align: left;
  margin-bottom: 1.6vw;
}
.administrators {
  width: 10.13vw;
  margin-right: 2px;
  vertical-align: middle;
}
.noble {
  width: 6.4vw;
  height: auto;
  margin-right: 2px;
  vertical-align: middle;
}
.level {
  width: 10.66vw;
  height: auto;
  vertical-align: middle;
}
.name {
  color: #6898df;
  font-family: PingFang SC;
  font-size: 3.46vw;
  font-weight: 400;
  line-height: 5.86vw;
}
.d_text_c {
  color: #ff752d !important;
}
.d_text {
  color: rgba(0, 0, 0, 0.9);
  font-family: PingFang SC;
  font-size: 3.46vw;
  font-weight: 400;
  line-height: 5.86vw;
  overflow-wrap: break-word;
  margin-left: 2px;
}
</style>
