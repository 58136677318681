<template>
  <div class="contact_assistant">
    <div class="close_wrap">
      <img src="@/assets/img/live/close.png" alt="" @click="handleClose" class="close_icon" />
    </div>
    <h1>联系助理</h1>
    <div class="assistant_list">
      <div class="scroll">
        <div class="assistant_item" v-for="(child, i) in props.assistantlist" :key="i">
          <img :src="filesCdnDomain + child.avatar" class="avart" v-if="child.avatar" />
          <img src="@/assets/img/icon/avart.png" alt="" v-else class="avart" />

          <div class="info">
            <div class="nick_name">{{ child.nickName }}</div>
            <div class="level">
              <img src="@/assets/img/live/star.png" alt="" v-for="item in 5" :key="item" />
            </div>
          </div>
          <div class="btn" @click="openChat(child)">联系助理</div>
        </div>
      </div>
    </div>
    <div class="konw_btn" @click="handleClose">知道了</div>
  </div>
</template>
<script setup name="ContactAssistant">
import { chat } from "@/untils/tim";
import TencentCloudChat from "@tencentcloud/chat";
import { useStore } from "vuex";
import { computed } from "vue";
import { showFailToast } from "vant";
import { useRouter } from "vue-router";
import Bus from "@/untils/event-bus";
const store = useStore();
const router = useRouter();
const userInfo = computed(() => store.state.userJs.userInfo);
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const emit = defineEmits(["close"]);
const props = defineProps({
  assistantlist: {
    type: Array,
    default: [],
  },
});
const handleClose = () => {
  emit("close");
};
function openChat(child) {
  if (userInfo.value.uid == child.uid) {
    showFailToast("不可以和自己私聊哦");
    return;
  }
  emit("close");
  let lock = false;

  //查看是否是好友，
  chat()
    .checkFriend({
      userIDList: [child.uid.toString()],
      type: TencentCloudChat.TYPES.SNS_CHECK_TYPE_SINGLE,
    })
    .then((res) => {
      // console.log(res);
      const { successUserIDList, failureUserIDList } = res.data;

      successUserIDList.forEach((element) => {
        if (
          element.userID == child.uid.toString() &&
          element.relation != "CheckResult_Type_NoRelation"
        ) {
          localStorage.setItem("imChatId", child.uid.toString());
          localStorage.setItem("imChatType", "C2C");
          Bus.$emit("openLiveChat");
          lock = true;
        }
      });
    })
    .then(() => {
      if (!lock) {
        //不是好友再加好友
        chat()
          .addFriend({
            to: child.uid.toString(),
            source: "AddSource_Type_Web",
            type: TencentCloudChat.TYPES.SNS_ADD_TYPE_BOTH,
          })
          .then((res) => {
            let message = chat().createTextMessage({
              to: child.uid.toString(),
              conversationType: "C2C",
              payload: {
                text: "你好！",
              },
            });
            chat()
              .sendMessage(message)
              .then((res) => {
                localStorage.setItem("imChatId", child.uid.toString());
                localStorage.setItem("imChatType", "C2C");
                Bus.$emit("openLiveChat");
              });
          });
      }
    });
}
</script>

<style scoped>
.contact_assistant {
  border-radius: 20px;
  background: #fff;
  width: 287px;
  height: 261px;
  position: relative;
  padding-top: 6.93vw;
  display: flex;
  flex-direction: column;
}

.close_wrap {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.close_icon {
  width: 24px;
  height: 24px;
}

h1 {
  color: #1f2022;
  text-align: center;
  font-family: PingFang SC;
  font-size: 4.8vw;
  font-weight: 600;
}
.assistant_list {
  flex: 1;
  margin: 3.2vw 0 4vw;
  overflow: hidden;
}
.scroll {
  height: 100%;
  overflow-y: scroll;
}
.scroll::-webkit-scrollbar {
  width: 4px;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.scroll::-webkit-scrollbar-track {
  border-radius: 0;
}
.assistant_item {
  padding: 1.6vw 6.4vw;
  display: flex;
  align-items: center;
}
.avart {
  width: 8.8vw;
  height: 8.8vw;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 2.13vw;
}
.info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.nick_name {
  color: #475569;
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-weight: 400;
  max-width: 24.8vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.level {
  display: flex;
  align-items: center;
  gap: 2px;
}
img {
  width: 3.2vw;
  height: 3.2vw;
}

.btn {
  padding: 6px 8px;
  border-radius: 6px;
  background: #f7eddc;
  color: #5a3314;
  text-align: center;
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-weight: 600;
  line-height: 14px;
  cursor: pointer;
}

.konw_btn {
  border-top: 1px solid #ebebeb;
  color: #222;
  font-family: PingFang SC;
  font-size: 4.26vw;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  padding: 3.73vw 0;
}
</style>
