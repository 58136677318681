<template>
  <div class="liveContainer">
    <div class="back" v-if="playerControlShow" ref="topBack">
      <img src="@/assets/img/icon/back_w.png" alt="" @click="goPage" class="back_icon" />
      <div class="live_msg">
        <div class="name">{{ liveInfo.title }}</div>
        <div class="audience_num">
          <img src="@/assets/img/icon/hot.png" class="hot" />
          {{ getHotNum(liveInfo.hot) }}
        </div>
      </div>
    </div>
    <div class="nolIive" v-if="!liveInfo">
      <div class="mask">
        <img src="@/assets/img/live/nolive.png" alt="" class="nolive_png" />
        <div class="text">主播休息中，去看看其他直播吧~</div>
      </div>
    </div>
    <div class="living" v-show="liveInfo" @click="playerControlShow = !playerControlShow">
      <div class="loading" v-if="loading">
        <img src="@/assets/img/logoIn/lognBgW.png" alt="" class="lognBgW" />
        <div class="loding_text">正在加载</div>
        <img src="@/assets/img/live/loding.gif" alt="" class="loding" />
      </div>
      <div
        ref="videoRef"
        class="player"
        id="PlayVideo"
        v-else
        style="width: 100%; height: 100%; position: relative"
      ></div>
      <PlayerControl
        @togglePlay="togglePlay"
        @fullscreen="fullscreen"
        @cancelFullScreen="cancelFullScreen"
        @refush="refush"
        :paused="paused"
        :fullScreenIshow="fullScreenIshow"
        v-if="!loading && playerControlShow"
      />
    </div>
  </div>
</template>
<script setup name="liveContainer">
import { getLiveInfo } from "@/assets/api/living";
import { useRouter } from "vue-router";
import { ref, onMounted, onBeforeUnmount, computed, nextTick } from "vue";
import DPlayer from "dplayer";
import PlayerControl from "./playerControl.vue";
import { useStore } from "vuex";
const store = useStore();
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const router = useRouter();
const liveInfo = ref({});
const noLive = ref(false);
const loading = ref(false);
const player = ref();
const paused = ref(false);
const fullScreenIshow = ref(false);
const playerControlShow = ref(false);
let dp = null;
defineExpose({ initPlayer });
function goPage() {
  if (getMobileOperatingSystem() == "Android" && fullScreenIshow.value) {
    cancelFullScreen();
    return;
  }
  //需要判断直接打开直播间链接还是用户自己点击直播间进入的
  if (history.state?.back) {
    router.back();
  } else {
    //直接打开直播间链接 返回按钮跳到首页
    router.push("/");
  }
}
function initPlayer(pullUrl, thumb) {
  dp = new DPlayer({
    container: document.getElementById("PlayVideo"),
    autoplay: true,
    loop: true,
    volume: 50,

    video: {
      url: liveInfo.value.pullUrl,
      pic: filesCdnDomain + liveInfo.value.thumb,

      type: "auto",
    },
    danmaku: {
      api: "https://" + window.location.host + "/danmu",

      id: "211312312",
      speedRate: 0.5,
      unlimited: false,
    },
    apiBackend: {
      read: function (options) {
        options.success([]);
      },
      send: function (options) {
        options.success();
      },
    },
  });
  dp.on("pause", () => {
    paused.value = false;
  });

  dp.on("play", () => {
    paused.value = true;
  });
  doPlay();
}
function doPlay() {
  dp.on("canplay", () => {
    dp.video.play().catch(() => {
      dp.video.muted = true;
      dp.play();
      dp.volume(50, true, false);
    });
  });
}
function togglePlay() {
  dp.toggle();
}
const topBack = ref();
function fullscreen() {
  if (getMobileOperatingSystem() == "Android") {
    fullScreenIshow.value = true;
    document.getElementById("PlayVideo").appendChild(topBack.value);
  }
  dp.fullScreen.request("browser");
}
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    return "iOS";
  } else if (userAgent.match(/Android/i)) {
    return "Android";
  } else {
    return "unknown";
  }
}
function cancelFullScreen() {
  dp.fullScreen.cancel("browser");
  fullScreenIshow.value = false;
}
function refush() {
  loading.value = true;
  dp.destroy();
  setTimeout(function () {
    loading.value = false;
  }, 2000);
  setTimeout(function () {
    initPlayer();
  }, 3000);
}
const emit = defineEmits(["setData"]);
function _getLiveInfo() {
  getLiveInfo({ anchorId: router.currentRoute.value.query.id })
    .then((res) => {
      //已开播有信息可查
      if (res.data) {
        liveInfo.value = res.data;
        let str = res.data.pullUrl.replace(".flv", ".m3u8");
        if (getPhoneType() == 2) {
          liveInfo.value.pullUrl = str;
        }
        emit("setData", res.data);
        initPlayer();
      }
      loading.value = false;
    })
    .catch(() => {
      loading.value = false;
    });
}
function getPhoneType() {
  var u = navigator.userAgent;
  var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
  if (isAndroid) {
    return 1;
  } else {
    return 2;
  }
}
import Bus from "@/untils/event-bus";
onMounted(() => {
  _getLiveInfo();
  Bus.$on("getNewHot", (val) => {
    nextTick(() => {
      liveInfo.value.hot = val;
    });
  });
});
onBeforeUnmount(() => {
  dp.destroy(); //关闭弹窗后销毁播放器
});
</script>
<style scoped>
.liveContainer {
  width: 100%;
  height: 100%;
  position: relative;
}
.back {
  position: absolute;
  top: 6.4vw;
  left: 3.73vw;
  z-index: 9;
  display: flex;
  align-items: center;
}
.back_icon {
  /* width: 5.33vw;
  height: 5.33vw; */
  width: 20px;
  height: 20px;
}
.live_msg .name {
  color: #fff;
  font-family: PingFang SC;
  /* font-size: 3.73vw; */
  font-size: 14px;
  font-weight: 500;
}
.audience_num {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgba(255, 255, 255, 0.65);
  font-family: PingFang SC;
  /* font-size: 2.66vw; */
  line-height: 14px;
  font-size: 10px;
  font-weight: 400;
  /* line-height: 3.73vw; */
  gap: 2px;
}
.hot {
  /* width: 2.66vw;
  height: 2.66vw; */
  width: 10px;
  height: 10px;
}

.player {
  width: 100%;
  height: 100%;
}
.living {
  width: 100%;
  height: 100%;
}
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000;
}
.lognBgW {
  margin-top: 17.33vw;
  width: 32vw;
  height: auto;
}
.loding_text {
  color: #fff;
  text-align: center;
  font-family: PingFang SC;
  font-size: 2.66vw;
  font-style: normal;
  font-weight: 400;
  line-height: 4.26vw;
  margin: 2.66vw 0;
}
.loding {
  width: 8vw;
  height: 8vw;
}

.nolIive {
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/live/bg.png") no-repeat;
  background-size: 100%;
  position: relative;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5.33vw);
}
.nolive_png {
  width: 38.4vw;
  margin-top: 2.66vw;
}
.text {
  margin-top: 3.2vw;
  color: #fff;
  font-family: PingFang SC;
  font-size: 3.46vw;
  font-weight: 400;
}
</style>
<style>
.dplayer-controller {
  opacity: 0;
}
.dplayer-mobile-play {
  display: none !important;
}
</style>
