import request from "@/untils/request.js";
//获取直播详情
export function getLiveInfo(query) {
  return request({
    url: "/live/live/getLiveInfo",
    method: "get",
    params: query,
  });
}
//用户直播间权限详情
export function userPowerLive(query) {
  return request({
    url: "/live/live/userAuth/info",
    method: "get",
    params: query,
  });
}
////获取平台助理列表
export function anchorAssistantList(query) {
  return request({
    url: "/live/live/assistant/list",
    method: "get",
    params: query,
  });
}
