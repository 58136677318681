<template>
  <div class="container_box">
    <div class="container_box_top">
      <div class="left_tab">
        <div
          class="tab_item"
          v-for="(item, i) in tab"
          :key="i"
          :class="tabActive == i ? 'tab_active' : ''"
          @click="changeTab(i)"
        >
          <div class="tab_text">{{ item }}</div>
          <div class="tab_line"></div>
          <div class="point" v-show="i == 2 && unreadCount > 0">
            {{ unreadCount > 99 ? "99+" : unreadCount }}
          </div>
        </div>
      </div>
      <div class="right_btn" :class="{ followed: props.livingInfo.isAttented == 1 }">
        <img
          :src="filesCdnDomain + props.livingInfo.avatar"
          alt=""
          class="head"
          v-if="props.livingInfo.avatar"
        />
        <img src="@/assets/img/icon/avart.png" alt="" v-else class="head" />
        <div class="text_box" @click="attentedBtn">
          <div class="text_box_top" v-if="props.livingInfo.isAttented == 0">
            <img src="@/assets/img/icon/follow.png" alt="" class="follow" />关注
          </div>
          <div class="text_box_top" v-else>已关注</div>
          <div class="text_box_bottom">
            {{ props.livingInfo.fansCount ? getHotNum(props.livingInfo.fansCount) : "" }}
          </div>
        </div>
      </div>
    </div>
    <div class="container_box_bottom">
      <Chat v-show="tabActive == 0" :imClose='livingInfo.imClose'/>
      
      <Anchor v-if="tabActive == 1" :livingInfo="livingInfo" :signature="signature" />
      <Msg v-show="tabActive == 2" />
    </div>
    <van-popup v-model:show="popupShow">
      <div class="pop_container">
        <img src="../../../assets/img/live/icon.png" alt="" class="po_icon" />
        <div class="popup_box">
          <div class="po_title">关注成功</div>
          <div class="po_tip">下载安装APP，第一时间查看主播开播信息</div>
          <div class="po_btn">
            <div class="cancle_btn" @click="popupShow = false">取消</div>
            <div
              class="confirm_btn"
              @click="
                router.push({
                  name: 'DownLoad',
                })
              "
            >
              下载APP
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script setup name="containerBox">
import TencentCloudChat from "@tencentcloud/chat";
import { chat } from "@/untils/tim";
import Chat from "./componentsChat/chat.vue";
import Anchor from "./anchorComponents/anchor.vue";
import Msg from "./msgComponents/msg.vue";
import { ref, computed, onMounted, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { showSuccessToast, showConfirmDialog } from "vant";
import { cancelFollow, addFollow, basicInfo } from "@/assets/api/user";
import Bus from "@/untils/event-bus";
const store = useStore();
const isLogin = ref(localStorage.getItem("isLogin"));
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const unreadCount = computed(() => store.state.imJs.unreadCount);
const props = defineProps({
  livingInfo: {
    type: Object,
    default: {},
  },
});
onMounted(() => {
  Bus.$on("openLiveChat", () => {
    tabActive.value = 2;
  });
});

const router = useRouter();
const tab = ref(["聊天", "主播", "消息"]);
const tabActive = ref(0);
const signature = ref("");
const popupShow = ref(false);
function changeTab(i) {
  if (i == 2 && !isLogin.value) {
    showConfirmDialog({
      title: "提示",
      message: "未登录，是否先登录",
    })
      .then(() => {
        router.push({
          name: "Login",
        });
      })
      .catch(() => {});
    return;
  }
  tabActive.value = i;
  if (i == 1 && !signature.value) {
    _basicInfo();
  }
}

function _basicInfo() {
  basicInfo({ platform: 3, userId: props.livingInfo.anchorid }).then((res) => {
    if (!res.data) return;

    signature.value = res.data.profile ? res.data.profile.signature : "";
  });
}
function attentedBtn() {
  if (props.livingInfo.isAttented == 0) {
    followBtn();
  } else {
    cancleFollow();
  }
}
function followBtn() {
  if (!isLogin.value) {
    showConfirmDialog({
      title: "提示",
      message: "未登录，是否先登录",
    })
      .then(() => {
        router.push({
          name: "Login",
        });
      })
      .catch(() => {});
    return;
  }
  addFollow({ uid: props.livingInfo.anchorid }).then((res) => {
    props.livingInfo.isAttented = 1;
    popupShow.value = true;
  });
}
function cancleFollow() {
  cancelFollow({
    uid: props.livingInfo.anchorid,
  }).then((res) => {
    showSuccessToast("已取消关注");
    props.livingInfo.isAttented = 0;
  });
}
</script>
<style scoped>
.container_box {
  height: 100%;
}
.container_box_top {
  height: 10.66vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.left_tab {
  margin-left: 4.8vw;
  width: 34.66vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.tab_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.2vw;
  position: relative;
  padding: 2px;
}
.point {
  position: absolute;
  color: #fff;
  font-size: 10px;
  background: #ef4444;
  top: -5px;
  right: -14px;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  line-height: 18px;
}
.tab_text {
  color: rgba(0, 0, 0, 0.45);
  font-family: PingFang SC;
  font-size: 3.46vw;
  font-weight: 400;
  line-height: 4.26vw;
}
.tab_line {
  width: 3.2vw;
  height: 4px;
  border-radius: 2px;
  background: transparent;
  margin-top: 2.4vw;
}

.tab_active .tab_line {
  background: #d8ad6d !important;
}
.tab_text {
  color: #000;
  font-weight: 600;
}

.followed {
  background: linear-gradient(282.71deg, #a5a9ae, #dedfe2) !important;
}
.right_btn {
  height: 100%;
  width: 26.13vw;
  background: linear-gradient(282.71deg, #d8ad6d 9.2%, #f5d6a8 90.8%);
  border-top-left-radius: 5.33vw;
  border-bottom-left-radius: 5.33vw;
  display: flex;
  align-items: center;
}
.head {
  margin: 0 2.13vw 0 4px;
  height: 8.53vw;
  width: 8.53vw;
  border-radius: 50%;
  object-fit: cover;
}
.text_box .text_box_top {
  display: flex;
  align-items: center;
  color: #fff;
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-weight: 500;
  margin-bottom: 2px;
}
.follow {
  height: 3.2vw;
  width: 3.2vw;
  margin-right: 4px;
}
.text_box_bottom {
  color: #fff;
  font-family: PingFang SC;
  font-size: 2.4vw;
  font-weight: 400;
}

.container_box_bottom {
  height: 100%;
  background: #fafafa;
}

.pop_container {
  height: 61.86vw;
  width: 76.53vw;
  position: relative;
  padding-top: 6.93vw;
}
.po_icon {
  position: absolute;
  width: 26.4vw;
  height: 18.66vw;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.popup_box {
  background: #fff;
  border-radius: 5.33vw;
  height: 54.93vw;
}
.po_title {
  padding-top: 14.13vw;
  color: #000;
  font-family: PingFang SC;
  font-size: 4.8vw;
  font-weight: 500;
}
.po_tip {
  color: #374151;
  font-family: PingFang SC;
  font-size: 3.73vw;
  font-weight: 400;
  text-align: left;
  line-height: 5.86vw;
  margin: 2.13vw 9.06vw 6.93vw;
}
.po_btn {
  height: 13.33vw;
  display: flex;
  align-items: center;
  padding: 0 3.73vw;
}
.po_btn div {
  flex: 1;
  text-align: center;
  font-family: PingFang SC;
  font-size: 4.26vw;
}
.cancle_btn {
  color: #9ca3af;
  font-weight: 400;
}
.confirm_btn {
  color: #d8ad6d;
  font-weight: 500;
}

</style>
<style>
.container_box .van-popup {
  background: transparent;
}
</style>
