<template>
  <div class="send_box" @click.stop="">
    <div class="send-header-bar">
      <img src="@/assets/img/live/icon1.png" alt="" class="noble" @click="openDialogBtn" />

      <div v-if="props.isMute" class="log_type">
        <span> 您已被禁言</span>
      </div>
      <div class="input" v-else-if="isLogin">
        <van-field
          v-model="messageContent"
          enterkeyhint="send"
          placeholder="来一起聊比赛吧…"
          @keydown.enter.exact.prevent="handleEnter"
          @focus="changeText"
          ref="inputRef"
          id="contenteditableDiv"
        />
        <img src="@/assets/img/live/emjio.png" alt="" class="emjio" @click="openEmoji" />
      </div>
      <div v-else class="log_type">
        <a @click="goLogin">登录</a>
        <span>参与主播互动 </span>
      </div>
      <img src="@/assets/img/live/gift.png" alt="" class="gift" @click="openDialogBtn" />
    </div>
    <div class="emoji_box" v-show="showEmoji">
      <div v-for="item in emojiName" class="emoji" :key="item" @click="chooseEmoji(item)">
        <img :src="emojiMap[item]" style="width: 36px; height: 36px" />
      </div>
    </div>
  </div>
</template>
<script setup name="sendBox">
import { useStore } from "vuex";
import { showToast } from "vant";
import { ref, onMounted, computed, onUnmounted, nextTick } from "vue";
import { emojiMap, emojiName } from "@/untils/emojiMap";
import { openDialog } from "../../../../untils/index";
const store = useStore();
const isLogin = ref(localStorage.getItem("isLogin"));
const messageContent = ref("");
const showEmoji = ref(false);
const emojiMap1 = ref("");
const emojiName1 = ref("");
const emojiUrl1 = ref("");
const props = defineProps({
  isMute: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["sendImRoomMsg"]);
function handleEnter() {
  //发送消息按钮
  if (!messageContent.value) {
    showToast("请输入需要发送的内容！");
    return;
  }
  //im发送消息成功后传把信息体给信息列表组件
  emit("sendImRoomMsg", messageContent.value);
  messageContent.value = "";
}
function changeText() {
  showEmoji.value = false;
}
function openEmoji() {
  showEmoji.value = !showEmoji.value;
}
const inputRef = ref();
function chooseEmoji(item) {
  messageContent.value = messageContent.value + item;
  showEmoji.value = false;
  const length = messageContent.value.length;
  nextTick(() => {
    inputRef.value.selectionStart = length;
    inputRef.value.selectionEnd = length;
    inputRef.value.focus();
  });
}
function openDialogBtn() {
  openDialog();
}

import { useRouter } from "vue-router";
const router = useRouter();
function goLogin() {
  router.push({
    name: "Login",
  });
}
onMounted(() => {
  window.addEventListener("click", function () {
    showEmoji.value = false;
  });
});
onUnmounted(() => {
  window.removeEventListener("click", function () {});
});
</script>

<style scoped>
.send_box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.send-header-bar {
  display: flex;
  align-items: flex-end;

  background: #fff;
  padding: 0 3.2vw 0 2.66vw;
}
.input {
  flex: 1;
  background: #f6f7fa;
  border-radius: 5.6vw;
  display: flex;
  align-items: center;
  margin: 2.4vw 3.2vw 2.4vw 6px;
  padding-right: 2.66vw;
}
.log_type {
  flex: 1;
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 3.73vw 0;
  font-family: PingFang SC;
  font-size: 3.73vw;
  font-weight: 400;
  color: #222;
}
.noble {
  width: 9.06vw;
  height: 9.06vw;
  margin: 7px 0;
}
.gift {
  width: 8vw;
  height: 8vw;
  margin: 2.4vw 0;
}
.emjio {
  width: 5.33vw;
  height: 5.33vw;
}
.emoji_box {
  height: 52.8vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  background: #f9fafb;
  padding: 2.66vw;
}
.emoji {
  width: calc(100% / 7);
}
</style>
<style>
.send-header-bar .van-cell {
  background: #f6f7fa;
  padding: 3px 2.66vw 3px 5.33vw !important;
  border-radius: 5.6vw;
  padding: 0 2.66vw 0 5.33vw;
}
.send-header-bar a {
  text-decoration: underline;
  color: #d8ad6d;
}
</style>
