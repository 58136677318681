<template>
  <div class="live">
    <div class="f_top">
      <DownloadApp></DownloadApp>
    </div>
    <div class="top_living"><LiveContainer @setData="setData" /></div>
    <div class="container">
      <Container :livingInfo="livingInfo" />
    </div>
  </div>
</template>
<script setup name="Live">
import { chat } from "@/untils/tim";
import Container from "./components/container.vue";
import LiveContainer from "./components/liveContainer.vue";
import { onBeforeUnmount, ref, computed } from "vue";
import { useStore } from "vuex";
import { showDialog } from "vant";
const store = useStore();
const livingInfo = ref();
const isLogin = ref(localStorage.getItem("isLogin"));
function setData(val) {
  livingInfo.value = val;
}
function imLogout() {
  chat()
    .logout()
    .then((res) => {
      console.log("游客退出登录");
    })
    .catch(() => {
      showDialog({
        title: "提示",
        message: "请先退出游客登录",
      }).then(() => {
        chat().logout();
      });
    });
}
onBeforeUnmount(() => {
  if (!isLogin.value || !localStorage.getItem("Token")) {
    imLogout();
  }
});
</script>

<style scoped>
.live {
  height: 100vh;
  background: #fafafa;
}
.f_top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.top_living {
  position: fixed;
  top: 16vw;
  left: 0;
  right: 0;
  height: 56vw;
  background-color: #000;
}
.container {
  position: fixed;
  top: 72vw;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
